<template>
    <div>
        <img style="width: 100%" src="https://cdn.mekoomall.com/static/pc/manager/ICP.jpg" alt="">
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>